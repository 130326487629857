import React from 'react';
import IconEnvelope from '@iconscout/react-unicons/icons/uil-envelope';
import IconSchedule from '@iconscout/react-unicons/icons/uil-schedule';
import image1 from '../../assets/images/Kontaktseite-halbfrei.png';
import image2 from '../../assets/images/Kontaktseite.jpg';
import './ContactTeaser.scss';

const ContactTeaser = ({ title, message }) => {
  const iconSize = 32;

  const footer = (
    <div className={'ContactTeaser__content-bottom'}>
      <a
        className={'ContactTeaser__link'}
        href="https://my.meetergo.com/siljaty/20min"
        target="_blank"
      >
        <span className={'ContactTeaser__link-icon'}>
          <IconSchedule className={'ContactTeaser__icon'} size={iconSize} />
        </span>
        <span>Erstgespräch buchen</span>
      </a>
      <a className={'ContactTeaser__link'} href="mailto:mail@siljaty.com">
        <span className={'ContactTeaser__link-icon'}>
          <IconEnvelope className={'ContactTeaser__icon'} size={iconSize} />
        </span>
        <span>E-Mail schreiben</span>
      </a>
    </div>
  );

  return (
    <div className={'ContactTeaser'}>
      <div className="ContactTeaser__images">
        <div className="ContactTeaser__crop-box">
          <img
            className={'ContactTeaser__image2'}
            src={image2}
            alt="Silja Tyllilä"
          />
        </div>
        <img
          className={'ContactTeaser__image1'}
          src={image1}
          alt="Silja Tyllilä"
        />
      </div>
      <div className={'ContactTeaser__content-box'}>
        <div className={'ContactTeaser__content-top'}>
          <h2 className={'ContactTeaser__title'}>
            {title || 'Du möchtest mit mir zusammenarbeiten?'}
          </h2>
          <p>
            {message ||
              'Hier kannst du einen Termin für ein kostenfreies, telefonisches Erstgespräch buchen. Ich rufe dich dann zu der Zeit an.'}
          </p>
        </div>
        {footer}
      </div>
    </div>
  );
};

export default ContactTeaser;
