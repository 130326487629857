import { graphql } from 'gatsby';
import React from 'react';
import AngebotStage from '../components/AngebotStage/AngebotStage';
import Button from '../components/Button/Button';
import ContactTeaser from '../components/ContactTeaser/ContactTeaser';
import Layout from '../components/Layout';
import Section from '../components/Section/Section';
import Seo from '../components/Seo';
import StageTab from '../components/StageTab/StageTab';

const AngebotSingle = ({ data, location, pageContext }) => {
  const { all } = data;
  const { parentTitle, subhead, color, image } = pageContext;
  const { html, frontmatter } = data.markdownRemark;
  const {
    title,
    seoTitle,
    description,
    buttonLink,
    buttonLabel,
    angebotTyp,
    contact,
  } = frontmatter;

  return (
    <Layout location={location}>
      <Seo
        location={location}
        title={seoTitle || title}
        description={description}
      />
      <AngebotStage
        title={parentTitle}
        description={subhead}
        color={color}
        image={image}
      />

      <div className={'StageTab__wrapper StageTab__wrapper--' + color}>
        <div className={'inner-wrapper'}>
          <div className={'StageTab__tabs'} id={'content'}>
            {all.nodes.map(({ id, frontmatter, fields }) => {
              const href = fields.slug
                .replace('/angebot/', `/angebot-${angebotTyp}/`)
                .replace(/\/$/, '');
              const selected = location.pathname.replace(/\/$/, '') === href;

              return (
                <StageTab
                  color={color}
                  key={id}
                  title={frontmatter.title}
                  subhead={frontmatter.subhead}
                  selected={selected}
                  href={href + '#content'}
                />
              );
            })}
          </div>
        </div>
      </div>

      <Section>
        <h1 className={'h1 main-heading'}>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        {buttonLink && (
          <div style={{ marginTop: '4rem' }}>
            <Button linkTo={buttonLink} styles={['right']}>
              {buttonLabel}
            </Button>
          </div>
        )}
      </Section>
      {!contact.hide && (
        <Section>
          <ContactTeaser title={contact.title} message={contact.message} />
        </Section>
      )}
    </Layout>
  );
};

export default AngebotSingle;

export const pageQuery = graphql`
  query AngebotBySlug($id: String!, $siblings: [String]) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        subhead
        seoTitle
        description
        buttonLabel
        buttonLink
        angebotTyp
        contact {
          hide
          title
          message
        }
      }
    }
    all: allMarkdownRemark(
      filter: { id: { in: $siblings } }
      sort: { fields: [frontmatter___position], order: ASC }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          subhead
          position
        }
      }
    }
  }
`;
