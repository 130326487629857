import { Link } from 'gatsby';
import React from 'react';
import './StageTab.scss';

const StageTab = ({ title, subhead, color, href, selected }) => {
  const content = (
    <>
      <div className={'StageTab__inner'}>
        <p className={'StageTab__title'}>{title}</p>
        <p className={'StageTab__subhead'}>{subhead}</p>
      </div>
    </>
  );
  const className = `StageTab StageTab--${color} ${
    selected ? 'is-selected' : ''
  }`;

  return selected ? (
    <div className={className}>{content}</div>
  ) : (
    <Link to={href} className={className}>
      {content}
    </Link>
  );
};

export default StageTab;
